// NOTE -  Helper functions to build JSONLD based on the template

export function Article(base, data, location) {
  base.headline = data.markdownRemark.frontmatter.title;
  base.publisher = {
    "@type": "Organization",
    "name": data.site.siteMetadata.title,
    "logo": {
      "@type": "ImageObject",
      "url": `${location.origin}/favicon.ico`
    }
  };
  base.url = location.origin;
  base.mainEntityOfPage = {
    "@type": "WebPage",
    "@id": `${location.origin}/articles/`
  };
  base.description = data.markdownRemark.frontmatter.description;
  base.articleBody = data.markdownRemark.html;
  return base;
}

export function ArticleBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": `${location.origin}/articles/`,
        "name": "Article",
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}

export function Blog(base, data, location) {
  base.headline = data.markdownRemark.frontmatter.title;
  base.image = `${location.origin}${data.markdownRemark.frontmatter.featured_image}`;
  base.publisher = data.site.siteMetadata.title;
  base.url = location.origin;
  base.dateCreated = data.markdownRemark.frontmatter.creation_date;
  base.description = data.markdownRemark.frontmatter.description;
  base.articleBody = data.markdownRemark.html;
  return base;
}

export function BlogBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": `${location.origin}/blog/`,
        "name": "Blog",
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}

export function Home(base, data, location) {
  base.name = data.site.siteMetadata.title;
  base.url = location.origin;
  base.logo = `${location.origin}/favicon.ico`
  return base;
}

export function HomeBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push({
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@type": "Thing",
      "@id": location.origin,
      "name": data.site.siteMetadata.title,
    }
  });
  return breadCrumb;
}

export function Landing(base, data, location) {
  base.headline = data.markdownRemark.frontmatter.title;
  base.image = location.origin + data.markdownRemark.frontmatter.intro_options.featured_image;
  base.aggregateRating = {
    "@type": "AggregateRating",
    "ratingValue": data.markdownRemark.frontmatter.intro_options.star_rating.split("-")[0]
  };
  base.review = {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": data.markdownRemark.frontmatter.intro_options.star_rating.split("-")[0],
      "reviewBody": data.markdownRemark.frontmatter.reviews.reviews[0].quote,
    },
  }
  return base;
}

export function LandingBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": `${location.origin}/products/`,
        "name": "Products",
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}

export function Payroll(base, data, location) {
  base.name = data.markdownRemark.frontmatter.title;
  base.brand = data.markdownRemark.frontmatter.title;
  base.description = data.markdownRemark.frontmatter.description;
  base.image = '';
  return base;
}

export function PayrollBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": `${location.origin}/payroll-providers/`,
        "name": "Payroll Providers",
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}

export function Showcase(base, data, location) {
  base.headline = data.markdownRemark.frontmatter.title;
  base.image = '';
  base.aggregateRating = {
    "@type": "AggregateRating",
    "ratingValue": ''
  }
  return base;
}

export function ShowcaseBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": `${location.origin}/showcase/`,
        "name": "Showcase",
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}

export function Static(base, data, location) {
  base.name = data.markdownRemark.frontmatter.title;
  base.description = data.markdownRemark.frontmatter.description;
  base.publisher = data.site.siteMetadata.title;
  return base;
}

export function StaticBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}

export function Tools(base, data, location) {
  base.name = data.markdownRemark.frontmatter.title;
  base.description = data.markdownRemark.frontmatter.description;
  base.publisher = data.site.siteMetadata.title;
  return base;
}

export function ToolsBreadCrumb(breadCrumb, data, location) {
  breadCrumb.itemListElement.push(
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@type": "Thing",
        "@id": location.origin,
        "name": data.site.siteMetadata.title,
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@type": "Thing",
        "@id": location.href,
        "name": data.markdownRemark.frontmatter.title,
      }
    }
  );
  return breadCrumb;
}