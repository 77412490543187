import React from 'react';
import Helmet from 'react-helmet';
import { Article, Blog, Home, Landing, Payroll, Showcase, Static, Tools, HomeBreadCrumb, BlogBreadCrumb, LandingBreadCrumb, PayrollBreadCrumb, ShowcaseBreadCrumb, StaticBreadCrumb, ToolsBreadCrumb } from './jsonLd-builder';



class Seo extends React.Component {
  
  addSchema(data, location) {
    const template = data.markdownRemark.frontmatter.template;
    const base = {
      "@context": "http://schema.org",
      "@type": data.markdownRemark.frontmatter.type,
    }
    const breadCrumb = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": []
    }
    switch (template) {

      case 'article-page':
        return [
          Article(base, data, location)
        ];

      case 'blog-page':
        return [
          Blog(base, data, location),
          BlogBreadCrumb(breadCrumb, data, location)
        ];

      case 'home-page':
        return [
          Home(base, data, location),
          HomeBreadCrumb(breadCrumb, data, location)
        ];

      case 'landing-page':
        return [
          Landing(base, data, location),
          LandingBreadCrumb(breadCrumb, data, location)
        ];

      case 'payroll-page':
        return [
          Payroll(base, data, location),
          PayrollBreadCrumb(breadCrumb, data, location)
        ];

      case 'showcase-page':
        return [
          Showcase(base, data,location),
          ShowcaseBreadCrumb(breadCrumb, data, location)
        ];

      case 'tools-page':
          return [
            Tools(base, data,location),
            ToolsBreadCrumb(breadCrumb, data, location)
          ];

      default:
        // NOTE -  Used for static-page template -> defaults to WebPage for JSONLD
        return [
          Static(base, data, location),
          StaticBreadCrumb(breadCrumb, data, location)
        ];
    }
  }

  render() {
    const { data, location } = this.props;
    return (
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name='description' content={data.site.siteMetadata.description} />
       <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous" />
        <script type='text/javascript' src={`https://get.payrollpro.co/trackcl.js?rtkcmpid=${data.markdownRemark.frontmatter.redtrack}`}></script>
        {this.addSchema(data, location).map((json, index) => {
          return (
            <script type="application/ld+base" key={index}>{`${JSON.stringify(json)}`}</script>
          );
        })}
      </Helmet>
    );
  }
}

export default Seo;
